function addTableAria() {
  try {
    const allTables = document.querySelectorAll('table');
    const allCaptions = document.querySelectorAll('caption');
    const allRowGroups = document.querySelectorAll('thead, tbody, tfoot');
    const allRows = document.querySelectorAll('tr');
    const allCells = document.querySelectorAll('td');
    const allHeaders = document.querySelectorAll('th');
    // this accounts for scoped row headers
    const allRowHeaders = document.querySelectorAll('th[scope=row]');

    for (let i = 0; i < allTables.length; i++) {
      allTables[i].setAttribute('role','table');
    }

    for (let i = 0; i < allCaptions.length; i++) {
      allCaptions[i].setAttribute('role','caption');
    }

    for (let i = 0; i < allRowGroups.length; i++) {
      allRowGroups[i].setAttribute('role','rowgroup');
    }

    for (let i = 0; i < allRows.length; i++) {
      allRows[i].setAttribute('role','row');
    }

    for (let i = 0; i < allCells.length; i++) {
      allCells[i].setAttribute('role','cell');
    }

    for (let i = 0; i < allHeaders.length; i++) {
      allHeaders[i].setAttribute('role','columnheader');
    }

    for (let i = 0; i < allRowHeaders.length; i++) {
      allRowHeaders[i].setAttribute('role','rowheader');
    }
  } catch (e) {
    console.log("addTableAria(): " + e);
  }
}

function toggleModal(modalId, hasScrollbar) {
    const modal = document.getElementById(modalId);
    const bodyElement = document.querySelector('body');

    if(modal !== null && getComputedStyle(modal).display === "flex") { // alternatively: if(modal.classList.contains("modal-show"))
        modal.classList.add("modal-hide");

        setTimeout(() => {
            document.body.style.overflow = "initial"; // Optional: in order to enable/disable page scrolling while modal is hidden/shown - in this case: "initial" <=> "visible"
            modal.classList.remove("modal-show", "modal-hide");
            modal.style.display = "none";
        }, 200);
    }
    else {
        document.body.style.overflow = "hidden"; // Optional: in order to enable/disable page scrolling while modal is hidden/shown
        modal.style.display = "flex";
        // modal.style.paddingTop = '6rem'

        if (hasScrollbar) {
           bodyElement.style.overflowY = 'scroll';
        }

        modal.classList.add("modal-show");
    }
}

function checkPosition() {
  let windowY = window.scrollY;
  if (windowY < scrollPos) {
    // Scrolling UP
    menus.classList.add('is-visible');
    menus.classList.remove('is-hidden');
  } else {
    // Scrolling DOWN
    menus.classList.add('is-hidden');
    menus.classList.remove('is-visible');
  }
  scrollPos = windowY;
}

function debounce(func, wait = 10, immediate = true) {
  let timeout;
  return function() {
    let context = this, args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function getGalleryModal(src) {
    const newImage = document.querySelector(".modal-content img");
    galleryModal.classList.add('active');
    modalImage.src = src;

    modalClose.addEventListener('click', () => {
        galleryModal.classList.remove('active');
        reloadPage();
    });

    window.addEventListener('keyup', (e) => {
        if (e.key === 'Escape') {
            galleryModal.classList.remove('active');
            reloadPage();
        }

        if (e.key === 'ArrowLeft') {
            newImage.setAttribute("src", prevImage());
        } else if (e.key === 'ArrowRight') {
            newImage.setAttribute("src", nextImage());
        }
    });

    let prevButton = document.querySelector(".photo-prev");
    let nextButton= document.querySelector(".photo-next");

    nextButton.addEventListener('click', () => {
      newImage.setAttribute("src", nextImage());
    });

    prevButton.addEventListener('click', () => {
      newImage.setAttribute("src", prevImage());
    });
}

function nextImage() {
    imgIndex++;

    if (imgIndex >= galleryImages.length) {
        imgIndex = 0
    }

    return galleryImages[imgIndex].src;
}

function prevImage() {
    imgIndex--;

    if (imgIndex < 0) {
        imgIndex = galleryImages.length - 1
    }

    return galleryImages[imgIndex].src
}

function reloadPage() {
    location.reload();
}

function setCookie(name, value, expiry) {
    let date = new Date();
    date.setTime(date.getTime() + expiry * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();

    document.cookie = name + '=' + value + '; ' + expires + '; path=/';
}

function getCookie(name) {
    const cookie = name + '=';
    const decoded = decodeURIComponent(document.cookie);
    const cookieArray = decoded.split('; ');
    let value;

    cookieArray.forEach(val => {
        if(val.indexOf(cookie) === 0) value = val.substring(cookie.length);
    });

    return value;
}

function cookieConsent() {
    if(!getCookie('cookie-consent')) {
        document.querySelector('#cookies').classList.add('show');
    }
}