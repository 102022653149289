const passwordField = document.querySelector('.form input[type="password"]');
const toggleIcon = document.querySelector('.form-group > i');
const modalTriggerButtons = document.querySelectorAll("[data-modal-target]");
const modals = document.querySelectorAll(".modal");
const modalCloseButtons = document.querySelectorAll(".modal-close");
const modalCloseForm = document.querySelectorAll(".modal-close-form");
const hasScrollbar = window.innerWidth > document.body.clientWidth;
const menuNavigations = document.querySelectorAll('[data-menu-target]');
const menuNavigation = document.getElementById('menu-navigation');
const menuFriends = document.getElementById('friends');
const menus = document.querySelector('.menus');
const toggleComments = document.querySelectorAll('[data-comments-target]');
const toggleComment = document.querySelectorAll('[data-comment-target]');
const emojiContainers = document.querySelectorAll('.emoji-container');
const messageTo = document.getElementById('message-to');

const emojis = [
    "😀", "😃", "😁", "😆", "😅", "🤣", "😂", "😇", "🥰", "😍", "😘", "🤫",
    "😏", "🤪", "😝", "🤔", "😴", "😭", "🤧", "🤮", "😞", "😓", "😷", "😡",
    "🤬", "💩", "❤️", "🧡", "💖", "💓", "💚", "💜", "💕", "👍", "👎", "👌",
    "🤘", "👌", "👊", "✌️", "👏", "🙏", "💪", "🍦", "🎂", "🍩", "🧁", "☕",
    "🍾", "🥂"
];

if (messageTo) {
    messageTo.nextElementSibling.addEventListener('click', (e) => {
        const options = messageTo.querySelectorAll('option');
        messageTo.classList.toggle('active');

        messageTo.addEventListener('change', (e) => {
            const currentValue = e.target.value;
            messageTo.classList.remove('active');
            e.target.value = currentValue;
        })

        options.forEach((option) => {
            console.log(option.target)
        });
    });
}

let scrollPos = 0;

emojiContainers.forEach(emojiContainer => {
    const emojiInput = document.getElementById(emojiContainer.previousElementSibling.id);

    emojis.forEach(emoji => {
        const emojiSpan = document.createElement('span');
        emojiSpan.classList.add('emoji');
        emojiSpan.innerText = emoji;
        emojiContainer.appendChild(emojiSpan);
    });

    emojiInput.addEventListener('focus', () => {
        emojiContainer.style.display = 'flex';
    });

    emojiInput.addEventListener("blur", () => {
      setTimeout(() => {
        emojiContainer.style.display = "none";
      }, 200);
    });

    emojiContainer.addEventListener("click", (e) => {
      emojiInput.value += e.target.innerText;
    });
});

if (document.body.scrollHeight < document.documentElement.clientHeight) {
    history.scrollRestoration = "manual";
}

toggleComments.forEach(elem => {
    elem.addEventListener('click', (e) => {
        const comment = document.getElementById(e.currentTarget.getAttribute("data-comments-target"));
        comment.classList.toggle('active');
    });
});

toggleComment.forEach(elem => {
    elem.addEventListener('click', (e) => {
        const comment = document.getElementById(e.currentTarget.getAttribute("data-comment-target"));
        comment.classList.toggle('active');
    });
});

menuNavigations.forEach(elem => {
    elem.addEventListener('click', e => {
        const menu = document.getElementById(e.currentTarget.getAttribute("data-menu-target"));

        menu.classList.toggle('active');

        if (menu.classList.contains('active') && menu.id === menuNavigation.id) {
            menuFriends.classList.remove('active');
        } else if(menu.classList.contains('active') && menu.id === menuFriends.id) {
            menuNavigation.classList.remove('active');

        }
    });
});


window.addEventListener('scroll', () => {
    if (menuFriends) {
        menuFriends.classList.remove('active');
    }

    if (menuNavigation) {
        menuNavigation.classList.remove('active');
    }
});

if (menus) {
    window.addEventListener('scroll', debounce(checkPosition));
}

modalTriggerButtons.forEach(elem => {
    elem.addEventListener("click", e => toggleModal(e.currentTarget.getAttribute("data-modal-target"), hasScrollbar));
});

modalCloseButtons.forEach(elem => {
    elem.addEventListener("click", e => toggleModal(e.currentTarget.closest(".modal").id));
});

modalCloseForm.forEach(elem => {
    elem.addEventListener("click", e => toggleModal(e.currentTarget.closest(".modal").id));
});

modals.forEach(elem => {
    elem.addEventListener("click", e => {
        if(e.currentTarget === e.target) toggleModal(e.currentTarget.id);
    });
});

document.addEventListener("keydown", e => {
    if(e.key === 'Escape' && document.querySelector(".modal.modal-show")) {
        toggleModal(document.querySelector(".modal.modal-show").id);
    }
});

if (passwordField) {
    toggleIcon.addEventListener('click', () => {
        if (passwordField.type === 'password') {
            passwordField.type = 'text';
            toggleIcon.classList.add('active');
        } else {
            passwordField.type = 'password';
            toggleIcon.classList.remove('active');
        }
    });
}

addTableAria();

document.querySelector('#cookies-btn').addEventListener('click', () => {
    document.querySelector('#cookies').classList.remove('show');
    setCookie('cookie-consent', true, 30)
});

window.addEventListener('load', cookieConsent);